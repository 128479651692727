export default [
  'a little boy named Jacob, who lived in a cabin in the woods with his grandmother',
  'a mouse named Whiskers who lived in an old house',
  'a prince named Jacob who lived in a castle',
  'a frog named Hopper who lived in a swamp',
  'a dog named Belle who lived in a house in the suburbs',
  'a dog named Max who lived on a farm',
  'a man named Jacob who worked in an office',
  'a lobster named Lenny who lived in the ocean',
  'a boy named Jacob who worked at a diner in a small town',
  'an old man named Jacob who lived with his cat in an old house by a creek',
  'a man named Jacob who built robots',
  'a man named Jacob who owned his own bakery',
  'a bear named Fuzzy who lived in the woods',
  'a stray cat named Mittens who lived in the alley',
  'a single father named Jacob who worked at a car dealership',
  'a robot named Turbo',
  'a man named Jacob who was afraid of clowns',
  'a boy named Jacob who hated school',
  'a kitten named Princess who loved to play',
  'an old man named Jacob who played the violin',
  'a boy named Jacob who loved animals',
  'a firefighter named Jacob who was very brave',
  'a police officer named Jacob who lived in the big city',
  'a mechanic named Jacob who loved cars',
  'a whale named Finny who lived in the ocean',
  'a dragon named Puff-Puff who lived in a cave',
  'a unicorn named Misty who wanted to make friends',
  'a young man named Jacob who wanted to make a living as an artist',
  'a teddy bear named Chubby who came to life when no one was home',
  'an old man named Jacob who lived by the park',
  'a duck named Wobbles who lived by a pond',
  'a boy named Jacob who loved to ride horses',
  'a boy named Jacob who wanted to become an actor',
  'a young man named Jacob who wanted to be a professional athlete',
  'a boy named Jacob who loved sea shells',
  'a man named Jacob who had a magic wand',
  'a poor farming family who struggled to put food on the table',
  'an orphan boy named Jacob who lived on the streets',
  'an orphan named Jacob who longed for a family',
  'a stray dog named Spot who longed for a home',
  'a boy named Jacob who wanted to make friends but was very shy',
  'a doctor named Jacob who loved his job',
  'a boy named Jacob who wished he could fly',
  'a father named Jacob who lived in a small house with his five children',
  'a plumber named Jacob who wanted to be a singer',
  'a boy named Jacob who dreamed of meeting an alien from a far away planet',
  'a boy named Jacob who wished for a puppy',
  'a man named Jacob who couldn\'t afford a home',
  'a man named Jacob who was the mayor of a small town',
  'a boy named Jacob who went to school in a small town',
  'a man named Jacob who lived with his roommates in a small apartment in a big city',
  'a young man named Jacob who worked as a cashier at a grocery store',
  'a young man named Jacob who worked as a barista at a small coffee shop',
  'a man named Jacob who loved to fish',
  'an artist named Jacob who loved to paint',
  'a young man named Jacob who loved to dance',
  'a boy named Jacob who loved his dog',
  'a boy named Jacob who loved his horse',
  'a boy named Jacob who had a pet dragon named Sparky',
  'a boy named Jacob who wanted to meet Santa Claus',
  'a man named Jacob who worked at the zoo',
  'a boy named Jacob who wanted to be a pirate',
  'a boy named Jacob who came from a poor family',
  'a racecar driver named Jacob',
  'a brilliant scientist named Jacob',
  'a student named Jacob who took his assignments very seriously',
  'a man named Jacob who worked at a pet store',
  'a zookeeper named Jacob',
  'a man named Jacob who loved to work out'
];