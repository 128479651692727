export default {
    apiKey: "AIzaSyCUfi-gotDJpy_wXhOPoIMRfMqrdA_blFo",
    authDomain: "jacobsgamehouse.firebaseapp.com",
    databaseURL: "https://jacobsgamehouse.firebaseio.com",
    projectId: "jacobsgamehouse",
    storageBucket: "jacobsgamehouse.appspot.com",
    messagingSenderId: "529121111976",
    appId: "1:529121111976:web:4c604023aa59e7c9e6b79d",
    measurementId: "G-0EF1CTPV14"
};
